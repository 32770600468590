export interface Flag {
    display: string;
    asterisk?: boolean;
}

export const flags: Record<string, Flag> = {
    '-Drag': { display: 'Drag' },
    '-de-Gay': { display: 'Gay' },
    '-en-Genderdoe': { display: 'Genderdoe' },
    '-pl-Dukaizmy': { display: 'Dukaizmy' },
    '-pl-Gay': { display: 'Gay' },
    '-pl-Rodzaj neutralny': { display: 'Rodzaj neutralny' },
    '-pl-Rodzaj nijaki': { display: 'Rodzaj nijaki' },
    'Abroromantic': { display: 'Abroromantic' },
    'Abrosexual': { display: 'Abrosexual' },
    'Achillean': { display: 'Achillean' },
    'Agender': { display: 'Agender' },
    'Alloromantic Asexual': { display: 'Alloromantic Asexual' },
    'Ambiamorous': { display: 'Ambiamorous' },
    'Ambiamorous_': { display: 'Ambiamorous' },
    'Anarcha-Queer': { display: 'Anarcha-Queer' },
    'Androgyne': { display: 'Androgyne' },
    'Androsexual': { display: 'Androsexual' },
    'Aporagender': { display: 'Aporagender' },
    'Archaeopronouns': { display: 'Archaeopronouns' },
    'Aroace': { display: 'Aroace' },
    'Aromantic Allosexual': { display: 'Aromantic Allosexual' },
    'Aromantic': { display: 'Aromantic' },
    'Asexual': { display: 'Asexual' },
    'Autigender': { display: 'Autigender' },
    'Bear': { display: 'Bear' },
    'Bicurious': { display: 'Bicurious' },
    'Bigender': { display: 'Bigender' },
    'Bigender_': { display: 'Bigender' },
    'Biromantic': { display: 'Biromantic' },
    'Bisexual': { display: 'Bisexual' },
    'Butch': { display: 'Butch' },
    'Ceteroromantic': { display: 'Ceteroromantic' },
    'Ceterosexual': { display: 'Ceterosexual' },
    'Cis Ally': { display: 'Cis Ally', asterisk: true },
    'Demiboy': { display: 'Demiboy' },
    'Demigender': { display: 'Demigender' },
    'Demigirl': { display: 'Demigirl' },
    'Demiromantic': { display: 'Demiromantic' },
    'Demisexual': { display: 'Demisexual' },
    'Diamoric': { display: 'Diamoric' },
    'Enbian': { display: 'Enbian' },
    'Fa\'afafine': { display: 'Fa\'afafine' },
    'Femme': { display: 'Femme' },
    'Gay': { display: 'Gay' },
    'Gay_': { display: 'Gay' },
    'Gender Questioning': { display: 'Gender Questioning' },
    'Genderfae': { display: 'Genderfae' },
    'Genderfaun': { display: 'Genderfaun' },
    'Genderfluid': { display: 'Genderfluid' },
    'Genderflux': { display: 'Genderflux' },
    'Genderqueer': { display: 'Genderqueer' },
    'Greyaromantic': { display: 'Greyaromantic' },
    'Greyasexual': { display: 'Greyasexual' },
    'Gynesexual': { display: 'Gynesexual' },
    'Heteroflexible': { display: 'Heteroflexible' },
    'Heteroromantic': { display: 'Heteroromantic', asterisk: true },
    'Heterosexual': { display: 'Heterosexual', asterisk: true },
    'Hijra': { display: 'Hijra' },
    'Homoflexible': { display: 'Homoflexible' },
    'Homoromantic': { display: 'Homoromantic' },
    'Intersex': { display: 'Intersex' },
    'LGBTQ': { display: 'LGBTQ' },
    'Leather Pride': { display: 'Leather Pride' },
    'Lesbian': { display: 'Lesbian' },
    'Lesbian_': { display: 'Lesbian' },
    'Lesbian__': { display: 'Lesbian' },
    'Lesbian___': { display: 'Lesbian' },
    'Lesbiromantic': { display: 'Lesbiromantic' },
    'Maverique': { display: 'Maverique' },
    'Monoamorous': { display: 'Monoamorous', asterisk: true },
    'Monogamous': { display: 'Monogamous', asterisk: true },
    'Muxe': { display: 'Muxe' },
    'Nebularomantic': { display: 'Nebularomantic' },
    'Neopronouns': { display: 'Neopronouns' },
    'Neopronouns_': { display: 'Neopronouns' },
    'Neutrois': { display: 'Neutrois' },
    'Nonbinary': { display: 'Nonbinary' },
    'Omniromantic': { display: 'Omniromantic' },
    'Omnisexual': { display: 'Omnisexual' },
    'Oriented Aroace': { display: 'Oriented Aroace' },
    'Pangender': { display: 'Pangender' },
    'Panromantic': { display: 'Panromantic' },
    'Pansexual': { display: 'Pansexual' },
    'Polyamorous': { display: 'Polyamorous' },
    'Polyamorous_': { display: 'Polyamorous' },
    'Polyamorous__': { display: 'Polyamorous' },
    'Polyamorous___': { display: 'Polyamorous' },
    'Polyamorous____': { display: 'Polyamorous' },
    'Polyromantic': { display: 'Polyromantic' },
    'Polysexual': { display: 'Polysexual' },
    'Pomoromantic': { display: 'Pomoromantic' },
    'Pomosexual': { display: 'Pomosexual' },
    'Progress Pride': { display: 'Progress Pride' },
    'Progress Pride_': { display: 'Progress Pride' },
    'Queer': { display: 'Queer' },
    'Queer_': { display: 'Queer' },
    'Queerian': { display: 'Queerian' },
    'Queerplatonic': { display: 'Queerplatonic' },
    'Quoiromantic': { display: 'Quoiromantic' },
    'Sapphic': { display: 'Sapphic' },
    'Sapphic_': { display: 'Sapphic' },
    'Sexuality Questioning': { display: 'Sexuality Questioning' },
    'Straight Ally': { display: 'Straight Ally', asterisk: true },
    'Toric': { display: 'Toric' },
    'Transfeminine': { display: 'Transfeminine' },
    'Transgender': { display: 'Transgender' },
    'Transmasculine': { display: 'Transmasculine' },
    'Transneutral': { display: 'Transneutral' },
    'Trigender': { display: 'Trigender' },
    'Trixic': { display: 'Trixic' },
    'Two Spirit': { display: 'Two Spirit' },
    'Two Spirit_': { display: 'Two Spirit' },
    'Unlabeled': { display: 'Unlabeled' },
    'Xenogender': { display: 'Xenogender' },
};

export const buildFlags = (locale: string): Record<string, Flag> => {
    const entries = Object.entries(flags).filter(([flagName, _]) => {
        return !flagName.startsWith('-') || flagName.startsWith(`-${locale}-`);
    });
    entries.sort((a, b) => a[1].display.localeCompare(b[1].display));
    return Object.fromEntries(entries);
};
